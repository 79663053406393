import { CrudModel } from './_crud';

export class UserModel extends CrudModel {
  constructor() {
    super('app/user');
  }

  async getPsychologist(psychologistUserId: number) {
    return this.post(null, 'getPsychologist', { id: psychologistUserId });
  }

  async getProfile() {
    return this.post(null, 'getProfile', {});
  }

  async updateProfile(userInfo: any) {
    return this.post(null, 'updateProfile', userInfo);
  }

  async updatePassword(currentPassword: string, password: string) {
    return this.post(null, 'updatePassword',  { currentPassword, password });
  }


}

export default new UserModel();
