






















import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import AusterButton from "./AusterButton.vue";
import AusterInput from "./AusterInput.vue";

// import authModel from "@/models/auth.model";
import { validateEntity } from "../models/entity-validation";

import userModel from "@/models/user.model";

@Component({
  components: {
    AusterButton,
    AusterInput,
  },
})
export default class ChangePasswordModal extends Vue {
  @Prop() openModal?: boolean;

  showModal = false;
  loading = false;

  passwordType = "password";
  passwordIcon = "visibility_off"

  userEdit: any = {
    currentPassword: "",
    password: "",
    passwordConfirmation: "",
  };

  fieldErrors: any = {};

  showPassword() {
    if (this.passwordType === "password" && this.passwordIcon === "visibility_off") {
      this.passwordType = "text"; this.passwordIcon = "visibility"
    } else {
      this.passwordType = "password"
      this.passwordIcon = "visibility_off"
    }
  }

  showPasswordModal() {
    this.showModal = !this.showModal;
  }

  closeModal() {
    this.showModal = false;
  }

  async updatePassword() {
    this.validateFields();
    if (Object.values(this.fieldErrors).filter(error => !!error).length) {
      this.$vs.notification({
        text: "Verifique os erros indicados",
        color:"#FF6767",
      });
      console.error(this.fieldErrors);
      return;
    }
    this.loading = true;
    try {
      await userModel.updatePassword(this.userEdit["currentPassword"], this.userEdit["password"]);
      this.$vs.notification({
        title: "Sucesso",
        text: "Senha atualizada com sucesso!",
        color: "#2E7179",
      });
      this.closeModal();
      this.$emit("updated");
      this.userEdit = {
        currentPassword: "",
        password: "",
        passwordConfirmation: "",
      };
      this.fieldErrors = {};
    } catch (error) {
      console.error(error);
      this.$vs.notification({
        title: "Erro",
        text: error.message === "API_FORBIDDEN" ? "Senha atual incorreta" : "Não foi possível atualizar. Tente novamente.",
        color: "#FF6767",
      });
    }
    this.loading = false;

  }

  validateFields() {
    const validationDefinition: any = [
      { key: "currentPassword", validation: { kind: "password" } },
      { key: "password", validation: { lengthMin: 8, lengthMax: 100 } },
      { key: "passwordConfirmation", validation: { kind: "match", matchKey: "password" } },
    ];

    this.fieldErrors = validateEntity(this.userEdit, validationDefinition, ["currentPassword", "password", "passwordConfirmation"]);
  }

  @Watch("userEdit", { deep: true })
  userEditChanged() {
    // if a previous error was found, update validation when data changes
    if (Object.keys(this.fieldErrors).length) {
      this.validateFields();
    }
  }
}
