










































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import AvailabilityEditor from "../views/pages/logged-in-page/my-profile-components/AvailabilityEditor.vue";
import AppointmentScheduler from "../views/pages/logged-in-page/my-profile-components/AppointmentScheduler.vue";

import AusterButton from "../components/AusterButton.vue";
import AusterInput from "../components/AusterInput.vue";
import AusterSelect from "../components/AusterSelect.vue";
import ChangePasswordModal from "../components/ChangePasswordModal.vue";

import { uploadFile } from "@/models/_request";
import userModel from "@/models/user.model";
import specialtyModel from "@/models/specialty.model";

import { validateEntity } from "../models/entity-validation";

@Component({
  components: {
    AvailabilityEditor,
    AppointmentScheduler,
    AusterButton,
    AusterInput,
    AusterSelect,
    ChangePasswordModal,
  },
})
export default class PsychologistProfile extends Vue {
  isEditing: boolean = false;

  saving: boolean = false;

  expandAbout: boolean = false;

  // changePasswordModal = false;

  psychologistAboutText = "";

  fieldErrors: any = {};

  userEdit: any = {};

  @Prop({ default: () => ({}) })
  psychologist: any;

  @Prop({ default: false })
  editBtn: boolean;

  specialtiesOptions = [];
  psychologistSpecialties = [];

  pixKeyKindOptions = [
    { name: "E-mail", value: "EMAIL", validationKind: "email" },
    { name: "CPF", value: "CPF", mask: "###.###.###-##", validationKind: "cpf" },
    { name: "CNPJ", value: "CNPJ", mask: "##.###.###/####-##", validationKind: "cnpj" },
    { name: "Celular", value: "CELLPHONE", mask: "(##) #####-####", validationKind: "phone" },
    { name: "Aleatória", value: "RANDOM", mask: "XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX", validationKind: "uuid" },
  ];

  genderOptions: any[] = [{ name: 'Masculino', value: 'M' }, { name: 'Feminino', value: 'F' }, { name: 'Outro', value: 'O' }];

  get pixKeyMask() {
    return this.pixKeyKindOptions.find(option => option.value === this.userEdit?.pixKeyKind)?.mask;
  }

  get pixKeyValidationKind() {
    return this.pixKeyKindOptions.find(option => option.value === this.userEdit?.pixKeyKind)?.validationKind;
  }

  async mounted() {
    this.seeLessAbout();
    this.userEdit = { ...this.psychologist };
    this.userEdit.gender = this.userEdit.gender?.charAt(0) || ""; // necessary to due vs-select bug
    //trazer sempre marcado fiqueOK
    this.userEdit.fiqueOk = true;
    // load specialties options and transform userSpecialties to specialties
    this.specialtiesOptions = await specialtyModel.search({});
    this.psychologistSpecialties = this.psychologist?.userSpecialties?.map(userSpecialty => userSpecialty.specialty) || [];    
  }

  seeMoreAbout() {
    this.expandAbout = true;
    this.psychologistAboutText = this.psychologist.about?.slice(0, this.psychologist.about.length);
  }

  seeLessAbout() {
    this.expandAbout = false;
    this.psychologistAboutText = this.psychologist.about?.slice(0, 300);
    if (this.psychologist.about?.length > 300) {
      this.psychologistAboutText += "...";
    }
  }

  editProfile() {
    this.isEditing = !this.isEditing;
  }

  async updateProfile() {
    this.validateFields();
    if (Object.values(this.fieldErrors).filter(error => !!error).length) {
      this.$vs.notification({ title: "Dados inválidos", text: "Verifique os erros indicados", color:"#FF6767" });
      console.error(this.fieldErrors);
      return;
    }
    this.saving = true;
    try {
      const userParam = { ...this.userEdit };
      delete userParam['userType'];
      delete userParam['register'];
      delete userParam['company'];
      await userModel.updateProfile({ ...userParam, id: undefined, role: undefined, status: undefined, cpf: undefined, gender: this.translateGender(this.userEdit.gender), userSpecialties: this.psychologistSpecialties.map(specialty => ({ specialty })) });
    } catch (error) {
      console.error(error);

      let errorMessage = "";
      if (error.properties[0].name === "crp") {
        errorMessage = "CRP deve ser menor ou igual a 7 caracteres.";
      } else {
        errorMessage = error.message;
      }
      this.$vs.notification({ title: "Erro", text: errorMessage, color: "#FF6767", });
    }

    this.saving = false;
    this.isEditing = false;
    this.$emit("updated");
  }

  async updatePicture(fileList: FileList) {
    const loading = this.$vs.loading({ color: '#FABA01' });
    try {
      const file = await uploadFile(fileList[0]);
      //console.log('result file', file);
      await userModel.updateProfile({ pictureUrl: file.url });
      //console.log(result);
      this.$vs.notification({ text: "Sua foto de perfil foi atualizada.", color: "#2E7179" });
      this.userEdit.pictureUrl = file.url;
    } catch(error) {
      this.$vs.notification({ text: "Não foi possível enviar. Tente novamente.", color: "#FF6767" });
      console.error(error);
    }
    loading.close();
  }

  @Watch("userEdit", { deep: true })
  userEditChanged() {
    // if a previous error was found, update validation when data changes
    if (Object.keys(this.fieldErrors).length) {
      this.validateFields();
    }
  }

  validateFields() {
    const validationDefinition: any = [
      { key: "phoneNumber", validation: { kind: "phone" } },
      { key: "email", validation: { kind: "email" } },
      { key: "crp", validation: { lengthMin: 7 }},
      { key: "gender", validation: {} },
    ];

    this.fieldErrors = validateEntity(this.userEdit, validationDefinition, ["name", "phoneNumber", "cpf", "birthDate", "gender", "email", "password", "passwordConfirmation", "terms", "crp", "gender"]);
  }

  translateGender(value: string) { // necessary to due vs-select bug
    switch (value) {
      case "M":
        return "MALE";
      case "F":
        return "FEMALE";
      default:
        return "OTHER";
    }
  }
}
