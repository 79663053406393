























import { Vue, Component } from "vue-property-decorator";
import { endOfWeek, startOfWeek, eachDayOfInterval, startOfDay, endOfDay, addMinutes, format, parseISO, addHours, addWeeks } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons'

import appointmentModel from "@/models/appointment.model";

interface Slot {
  id?: number;
  time: Date;
  status: "AVAILABLE" | "APPOINTMENT" | "UNAVAILABLE" | "LOADING";
}

@Component({
  components: {
    ClipLoader,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  filters: {
    formatMonthYear(date) {
      return format(date, "MMMM/yyyy", { locale: ptBR });
    },
    formatDayMonth(date) {
      return format(date, "dd/MM");
    },
    formatDayOfWeek(date) {
      return format(date, "EEE", { locale: ptBR }).toUpperCase();
    },
    formatTime(date) {
      return format(date, "HH:mm");
    },
  }
})
export default class AvailabilityEditor extends Vue {
  referenceDate = new Date();

  slotOptionsByDay: {
    day: Date;
    slots: Slot[];
  }[] = [];

  async mounted() {
    await this.loadSlotsAndAppointments();
  }

  async changeWeek(numberOfWeeks: number) {
    this.referenceDate = addWeeks(this.referenceDate, numberOfWeeks);
    await this.loadSlotsAndAppointments();
  }

  async loadSlotsAndAppointments() {
    this.slotOptionsByDay = [];

    const fromDate: Date = startOfWeek(this.referenceDate);
    const toDate: Date = endOfWeek(this.referenceDate);

    const [availableSlots, appointments] = await Promise.all([appointmentModel.getAvailableSlots(fromDate, toDate), appointmentModel.getSchedule(fromDate, toDate)]);

    // console.log('logD slots', availableSlots);
    // console.log('logD appointments', appointments);

    this.slotOptionsByDay = eachDayOfInterval({ start: fromDate, end: toDate }).map(day => ({
      day,
      slots: this.getHalfHoursOfDay(day).map(time => {
        const slot: Slot = { time, status: "UNAVAILABLE" };

        // for each time slot, check if it is already available or an appointment has been scheduled
        const availableSlot = availableSlots.find(slot => parseISO(slot.startDateTime.replace('Z','')).getTime() === time.getTime());
        if (availableSlot) {
          slot.id = availableSlot.id;
          slot.status = "AVAILABLE";
          return slot; // if slot is available, it is not necessary to check appointments
        }
        const appointment = appointments.find(appointment => parseISO(appointment.startDateTime.replace('Z','')).getTime() === time.getTime());
        if (appointment) {
          slot.id = appointment.id;
          slot.status = "APPOINTMENT";
        }

        return slot;
      }),
    }));
  }

  async toggleSlotAvailability(slot: Slot) {
    const originalStatus = slot.status;
    slot.status = "LOADING";
    try {
      switch (originalStatus) {
        case "UNAVAILABLE": {
          const appointment = await appointmentModel.createSlot(slot.time);
          slot.status = "AVAILABLE";
          slot.id = appointment.id;
          break;
        }
        case "AVAILABLE":
          await appointmentModel.removeSlot(slot.id);
          slot.status = "UNAVAILABLE";
          slot.id = undefined;
          break;
        case "APPOINTMENT":
          slot.status = originalStatus;
          this.$vs.notification({ text: "Não é possível modificar horários com consultas marcadas." });
          break;
      }
    } catch (error) {
      console.error(error);
      if (error.message === "API_FORBIDDEN") {
        this.$vs.notification({ text: "Não foi possível disponibilizar o horário. Verifique se sua chave PIX está cadastrada.", color: "danger" });
      } else {
        this.$vs.notification({ text: "Não foi possível alterar o horário.", color: "danger" });
      }
      slot.status = originalStatus;
    }
  }

  getHalfHoursOfDay(day: Date): Date[] {
    const halfHours = [];
    for (let time = addHours(startOfDay(day), 6); time <= endOfDay(day); time = addMinutes(time, 30)) { // half hours from 06:00 to 23:30
      halfHours.push(time);
    }
    return halfHours;
  }

  getSlotTitleText(slot: Slot) {
    switch (slot.status) {
      case "UNAVAILABLE":
        return "Horário não disponível";
      case "AVAILABLE":
        return "Horário disponível";
      case "APPOINTMENT":
        return "Horário com consulta marcada";
      case "LOADING":
        return "Carregando...";
    }
  }
}
