



























import { Vue, Component, Prop } from "vue-property-decorator";
import { endOfWeek, startOfWeek, eachDayOfInterval, format, parseISO, addWeeks, isSameDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons'

import AusterInputModal from "@/components/AusterInputModal.vue";
import AusterSelect from "@/components/AusterSelect.vue";

import appointmentModel from "@/models/appointment.model";

@Component({
  components: {
    ClipLoader,
    ChevronLeftIcon,
    ChevronRightIcon,
    AusterInputModal,
    AusterSelect,
  },
  filters: {
    formatMonthYear(date) {
      return format(date, "MMMM/yyyy", { locale: ptBR });
    },
    formatDayMonth(date) {
      return format(date, "dd/MM");
    },
    formatDayOfWeek(date) {
      return format(date, "EEE", { locale: ptBR }).toUpperCase();
    },
  }
})
export default class AppointmentScheduler extends Vue {
  @Prop({ default: {} })
  psychologistUser: any;

  referenceDate = new Date();

  slotOptionsByDay: {
    day: Date;
    slots: { id: number, time: Date }[];
  }[] = [];

  showAppointmentConfirmation = false;
  appointmentKindOptions = [{ name: '45 min', value: 'SINGLE' }];
  requestedSlot: any = {};
  requestedKind: "SINGLE" | "DOUBLE" = "SINGLE";

  async mounted() {
    await this.loadSlots();
  }

  async changeWeek(numberOfWeeks: number) {
    this.referenceDate = addWeeks(this.referenceDate, numberOfWeeks);
    await this.loadSlots();
  }

  async loadSlots() {
    this.slotOptionsByDay = [];

    const fromDate: Date = startOfWeek(this.referenceDate);
    const toDate: Date = endOfWeek(this.referenceDate);

    const availableSlots = await appointmentModel.getAvailableSlots(fromDate, toDate, this.psychologistUser.id);    
    availableSlots.forEach(slot => {
      slot.startDateTime = slot.startDateTime.replace('Z', '');
    });
    //console.log('logD availableSlots', availableSlots);    

    this.slotOptionsByDay = eachDayOfInterval({ start: fromDate, end: toDate }).map(day => ({
      day,
      slots: availableSlots.filter(slot => isSameDay(parseISO(slot.startDateTime.replace('Z','')), day)),
    }));
  }

  requestAppointment(slot: any) {
    
    if (this.$store.state.activeUser?.role !== "PATIENT") {
      this.$vs.notification({ color: "danger", title: "Não permitido", text: "Somente pacientes podem agendar consultas." });
      return;
    }
    /*
    this.requestedSlot = slot;
    this.showAppointmentConfirmation = true;
    */
    //console.log(slot);
    this.$router.push(`/dash/consulta/${slot.id}`)
  }

  async scheduleAppointment() {
    const loading = this.$vs.loading();
    try {
      const appointment = await appointmentModel.schedule(this.requestedSlot.id, this.requestedKind);
      this.$vs.notification({ color: "success", title: "Agendamento realizado", text: "Efetue o envio do voucher." });
      this.$router.push(`/dash/consulta/${appointment.id}`);
    } catch (error) {
      console.error(error);
      if (error.message === "API_NOT_FOUND") {
        this.$vs.notification({ color: "danger", title: "Horário indisponível", text: "Este horário já foi reservado ou não está disponível para consultas de 45 minutos.", duration: 10000 });
      } else {        
        this.$vs.notification({ color: "danger", title: "Erro de agendamento", text: `Não foi possível agendar a consulta. ${error?.message ? error.message : 'Verifique sua conexão e tente novamente.'}` });
      }
    }
    loading.close();
  }
}
