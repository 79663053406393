











import {
  Vue, Prop, Component, Watch
} from "vue-property-decorator";

@Component({})
export default class AusterSelect extends Vue {
  @Prop()
  options: { name: string, value: string }[];

  @Prop({ default: "" })
  value: any;

  @Prop()
  placeholder: string;

  @Prop({ default: "" })
  name: string;

  @Prop({ default: "" })
  error: string;

  selectKey: number = 0;

  @Watch("options")
  reloadSelect() {
    this.selectKey++;
  }
}
